<template>
  <!-- 应急充电 -->
  <div class="charge">
    应急充电
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
